import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { cleanSession, REACT_APP_INTERCOM_APP_ID, getFullName } from '@commons';
import { useMe } from '@commons';
import OnBoardPage from 'routes/OnBoarding';
import { useRouterStore } from 'store/router';
import { useIntercom } from 'react-use-intercom';
const requiredRoles = ['PROVIDER_CONTACT', 'PROVIDER_RESPONSIBLE'];

const PrivateRoute: React.FC<
  React.PropsWithChildren<
    RouteProps & { component: React.FC<React.PropsWithChildren<any>> }
  >
> = ({ component: Component, ...rest }) => {
  const { hardShutdown, update } = useIntercom();
  // use this after login on redirect on targetLocation
  const { targetlocation, updateRouteStoreState, resetRouteStoreSates } =
    useRouterStore();

  const token = localStorage.getItem('token');
  const history = useHistory();
  const { pathname } = useLocation();
  const { isLoading, me, isError } = useMe();
  // INTERCOM CONNECT
  React.useEffect(() => {
    const user = me;
    if (user) {
      const flatRoles = user?.roles?.map(role => role.name);
      const authorized = flatRoles?.some(role => requiredRoles.includes(role));
      if (authorized) {
        update({
          customAttributes: {
            app_id: REACT_APP_INTERCOM_APP_ID,
            user_id: user?.cognitoUserId,
            name: getFullName(user),
            email: user?.email,
            created_at: user?.createdAt,
            connecteed_role: 'PROVIDER_ADMIN',
            phone: `+${user?.phone}`,
            jobtile: user?.jobTitle,
          },
        });
      }
    }
  }, [me]);

  React.useEffect(() => {
    // use this after login on redirect on targetLocation
    if (!me) {
      if (pathname !== '/on-boarding' && pathname !== '/dashboard') {
        if (targetlocation === undefined && pathname !== targetlocation) {
          console.log('UPDATE TARGETLOCATION');
          updateRouteStoreState({ targetlocation: pathname });
        }
      }
    }
    if (me && me?.onboardingStep?.ableToAccessSite) {
      if (targetlocation && targetlocation !== pathname) {
        history?.push(targetlocation);
        resetRouteStoreSates({});
      }
      if (targetlocation === pathname) {
        resetRouteStoreSates({});
      }
    }
  }, [pathname, me, targetlocation]);

  if (!token) {
    return <Redirect to="/login" />;
  }

  const jwt: any = jwtDecode(token);

  const current_time = Date.now() / 1000;
  if (jwt.exp < current_time) {
    /* expired */
    cleanSession();
    return <Redirect to="/login" />;
  }

  if (isLoading || !me) {
    return null;
  }

  if (isError) {
    cleanSession();
    hardShutdown();
    return <Redirect to="/login" />;
  }

  const { roles, onboardingStep: { ableToAccessSite } = {} } = me;
  const flatRoles = roles?.map(role => role.name);
  const authorized = flatRoles?.some(role => requiredRoles.includes(role));

  if (!authorized || me?.deactivated === true) {
    cleanSession();
    hardShutdown();
    return <Redirect to="/login" />;
  }

  if (!ableToAccessSite) {
    return <OnBoardPage />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;

import {
  uploadFile,
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  getFormulaDutyFormulaAmount,
} from '@commons';
import {
  EFileType,
  EStandByDutyUnits,
  IFile,
  IJoinedMission,
  IStandByDutyFormulaLine,
  IStandByDutyLine,
} from '@freelancelabs/teoreme-commons';
import { EuroIcon } from 'components/icons';
import {
  Box,
  Button,
  Cell,
  Flex,
  FormControl,
  Grid,
  Input,
  StaticSelectControlled,
  Text,
  NumberFormInputControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';

type Line = IStandByDutyLine & {
  lock?: boolean;
  key?: string;
};
type FormValues = Line & {
  uuid: string;
  standByDutyFormulaLineUuid?: string;
  providerFormula?: any;
  customerformula?: string;
  formulaParameters?: any;
  attachment?: IFile;
  amount?: number;
  unit?: EStandByDutyUnits;
  //margin?: number;
  lock: boolean;
};
type AddDutyLineProps = {
  line: Line;
  readOnly?: boolean;
  avalaibleFormulas: any[];
  mission: IJoinedMission;
  onCreateOrUpdateLine: (line: Line) => void;
  onDeleteLine: (line: Line) => void;
  unLockToUpdate?: (line: Line) => void;
  isEditable?: boolean;
};
const grid4 = {
  xs: '3',
  sm: '3',
  md: '3',
  lg: '3',
  xl: '3',
};
const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '2',
};
export const AddDutyLine: React.FC<
  React.PropsWithChildren<AddDutyLineProps>
> = ({
  line,
  readOnly,
  isEditable,
  avalaibleFormulas,
  mission,
  onCreateOrUpdateLine,
  onDeleteLine,
  unLockToUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attachmentFile, setAttachmentFile] = useState<File | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingFile, setLoadingFile] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...line,
    },
  });

  const standByDutyFormulaLineUuid = watch('standByDutyFormulaLineUuid');
  const formulaSelected: IStandByDutyFormulaLine = avalaibleFormulas?.find(
    af => af?.key === standByDutyFormulaLineUuid
  )?.data;
  const input = watch('formulaParameters.INPUT');
  const calculateProviderAmount = () => {
    try {
      const resultA = getFormulaDutyFormulaAmount(
        {
          providerFormula:
            line?.providerFormula || formulaSelected?.providerFormula,
          customerFormula: '',
          formulaParameters: {
            INPUT: input,
            PROVIDER_RATE: mission?.billing?.contractorRate,
            I_RATE: mission?.billing?.internalRate,
          },
        },
        'PROVIDER'
      );
      return String(resultA);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  };
  const amount = calculateProviderAmount();
  const onSubmit = async (data: FormValues) => {
    const onError = false;
    setLoading(true);
    // let attachmentResponse;
    try {
      if (attachmentFile) {
        const uploadResponse = await uploadFile({
          file: attachmentFile as File,
          fileType: EFileType?.TEMPORARY,
          missionRef: mission?.reference,
          actionType: 'upload',
        });

        if (!uploadResponse) return;

        // attachmentResponse = {
        //   fileLocation: uploadResponse.fileLocation,
        //   fileName: uploadResponse.fileName,
        //   eTag: uploadResponse.eTag,
        // };
      }
      if (!onError) {
        const submitValues = {
          uuid: line?.uuid,
          key: line?.uuid
            ? undefined
            : line?.key || String(new Date()?.getTime()),
          standByDutyFormulaLineUuid: standByDutyFormulaLineUuid,
          providerFormula:
            line?.providerFormula || formulaSelected?.providerFormula,
          formulaParameters: {
            INPUT: Number(data.formulaParameters?.INPUT),
            PROVIDER_RATE: mission?.billing?.contractorRate,
            I_RATE: mission?.billing?.internalRate,
          },
          amount: Number(amount),
          lock: true,
        };
        //@ts-ignore
        onCreateOrUpdateLine(submitValues);
      }
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  };
  // const onChangeFile = (files: any) => {
  //   setLoadingFile(true);
  //   clearErrors('attachment');
  //   setAttachmentFile(files[0]);
  //   setLoadingFile(false);
  // };

  return (
    <Box width={1 / 1} mt={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid cols={12}>
          <Cell x-span={grid4}>
            <FormControl required label="Type prestation" p={'5px'}>
              <StaticSelectControlled
                isDisabled={readOnly}
                control={control}
                rules={{ required: 'Ce champs est requis' }}
                name="standByDutyFormulaLineUuid"
                options={avalaibleFormulas}
              />
            </FormControl>
          </Cell>
          <Cell x-span={grid4}>
            <FormControl
              required
              label="Travaillé"
              p={'5px'}
              mx={'5px'}
              errorMessage={
                errors?.formulaParameters?.INPUT?.type === 'min'
                  ? 'Ne peut pas être inférieur à 0'
                  : errors?.formulaParameters?.INPUT?.message
              }
            >
              <Flex>
                <NumberFormInputControlled
                  control={control}
                  name="formulaParameters.INPUT"
                  maxDecimal={3}
                  step={'0.001'}
                  type="number"
                  isDisabled={readOnly || !formulaSelected}
                  isFullWidth
                  rules={{
                    required: 'Ce champ est requis',
                    min: 0,
                  }}
                />
                {formulaSelected?.unit && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    ml={'-3px'}
                    p={'5px'}
                    mx={'5px'}
                    minWidth={90}
                    borderTopRightRadius="8px"
                    borderBottomRightRadius="8px"
                    backgroundColor={Theme?.colors?.primary?.default}
                  >
                    <Text color="white">
                      {STAND_BY_DUTY_UNIT_TRANSLATE_FR[formulaSelected?.unit]}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </FormControl>
          </Cell>
          <Cell x-span={grid2}>
            <FormControl label="Total" p={'5px'} mx={'5px'}>
              <Input
                isDisabled
                value={amount}
                icon={<EuroIcon />}
                isFullWidth
              />
            </FormControl>
          </Cell>
          <Cell x-span={grid4}>
            <Flex
              width={1 / 1}
              alignItems="center"
              justifyContent="center"
              mt={30}
            >
              {readOnly && isEditable ? (
                <>
                  <Button
                    onClick={() => unLockToUpdate && unLockToUpdate(line)}
                    height="40px"
                    ml={'5px'}
                    mr={'5px'}
                    variant="ghost"
                  >
                    Modifier
                  </Button>
                  <Button
                    onClick={() => onDeleteLine(line)}
                    height="40px"
                    variant="ghostError"
                  >
                    Supprimer
                  </Button>
                </>
              ) : (
                <Box>
                  {!readOnly && (
                    <Button
                      isLoading={loading}
                      height="40px"
                      minWidth="125px"
                      variant="filled"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  )}
                </Box>
              )}
            </Flex>
          </Cell>
          <Cell x-span={{ xs: '12' }}>
            {standByDutyFormulaLineUuid && (
              <Flex>
                <Text minWidth={200} variant="p" fontWeight="bolder">
                  Description de la prestation :
                </Text>
                <Text>&nbsp;{formulaSelected?.description}</Text>
              </Flex>
            )}
          </Cell>
        </Grid>
      </form>
    </Box>
  );
};

AddDutyLine.displayName = 'AddDutyLine';

export default AddDutyLine;

import { ApiError } from '../types/error.types';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { checkToken } from './checkToken';
import { deleteAllCognitoCookies, parser } from './parser';
import { API_ERRORS_FR } from '@freelancelabs/teoreme-commons';
import { jwtDecode } from 'jwt-decode';
import { cleanSession } from './cleanSession';
export const axiosInstance = Axios.create();
export const axiosInstanceNoAlert = Axios.create();
type FetcherParams = {
  body?: any;
  headers?: any;
  method?: AxiosRequestConfig['method'];
  responseType?: AxiosRequestConfig['responseType'];
};
export const authoriseFetch = (url: string, params?: FetcherParams) => {
  const isImpersonateAction = localStorage?.token
    ? (jwtDecode(localStorage?.token) as any)?.isImpersonateAction
    : false;
  if (isImpersonateAction === true) {
    if (
      params?.method?.toUpperCase() === 'GET' ||
      params?.method === undefined ||
      url?.includes('search') ||
      url?.includes('/statistics/provider')
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export const fetcherThirdParty = async <RequestType = any, ResponseType = any>(
  url: string,
  params?: FetcherParams,
  disableAlert?: boolean
): Promise<ResponseType> => {
  if (disableAlert === true) {
    return axiosInstanceNoAlert
      .request<RequestType, AxiosResponse<ResponseType>>({
        method: params?.method,
        url,
        responseType: params?.responseType,
        data: params?.body,
        headers: {
          ...params?.headers,
        },
        transformResponse: response => JSON.parse(response, parser),
      })
      .then(response => response.data)
      .catch((err: ApiError) => {
        throw err;
      });
  } else {
    return axiosInstance
      .request<RequestType, AxiosResponse<ResponseType>>({
        method: params?.method,
        url,
        responseType: params?.responseType,
        data: params?.body,

        headers: { ...params?.headers },
        transformResponse: response => JSON.parse(response, parser),
      })
      .then(response => response.data)
      .catch((err: ApiError) => {
        throw err;
      });
  }
};
export const fetcherNoBearerToken = async <
  RequestType = any,
  ResponseType = any,
>(
  url: string,
  params?: FetcherParams,
  disableAlert?: boolean
): Promise<ResponseType> => {
  if (disableAlert === true) {
    return axiosInstanceNoAlert
      .request<RequestType, AxiosResponse<ResponseType>>({
        method: params?.method,
        url,
        responseType: params?.responseType,
        data: params?.body,

        headers: {
          ...params?.headers,
        },
        transformResponse: response => JSON.parse(response, parser),
      })
      .then(response => response.data)
      .catch((err: ApiError) => {
        throw err;
      });
  } else {
    return axiosInstance
      .request<RequestType, AxiosResponse<ResponseType>>({
        method: params?.method,
        url,
        responseType: params?.responseType,
        data: params?.body,

        headers: { ...params?.headers },
        transformResponse: response => JSON.parse(response, parser),
      })
      .then(response => response.data)
      .catch((err: ApiError) => {
        throw err;
      });
  }
};

export const fetcher = async <RequestType = any, ResponseType = any>(
  url: string,
  params?: FetcherParams,
  disableAlert?: boolean
): Promise<ResponseType> => {
  const token = await checkToken(
    localStorage.getItem('token'),
    localStorage.getItem('refreshToken')
  );
  let isAuthorise = authoriseFetch(url, params);
  if (isAuthorise) {
    if (disableAlert === true) {
      return axiosInstanceNoAlert
        .request<RequestType, AxiosResponse<ResponseType>>({
          method: params?.method,
          url,
          responseType: params?.responseType,
          data: params?.body,

          headers: token
            ? {
                Authorization: `Bearer ${token}`,
                ...params?.headers,
              }
            : { ...params?.headers },
          transformResponse: response => JSON.parse(response, parser),
        })
        .then(response => response.data)
        .catch((err: ApiError) => {
          throw err;
        });
    } else {
      return axiosInstance
        .request<RequestType, AxiosResponse<ResponseType>>({
          method: params?.method,
          url,
          responseType: params?.responseType,
          data: params?.body,

          headers: token
            ? {
                Authorization: `Bearer ${token}`,
                ...params?.headers,
              }
            : { ...params?.headers },
          transformResponse: response => JSON.parse(response, parser),
        })
        .then(response => response.data);
      // .catch((err: ApiError) => {
      //   throw err;
      // });
    }
  } else {
    return Promise.reject(
      `IMPERSONATE_FRONT_PERMISSION_DENIED TO CALL API : ${url}`
    );
  }
};

export const registerInterceptor = (alert: any) => {
  axiosInstance.interceptors.response.use(
    res => res,
    async (error: AxiosError) => {
      //@ts-ignore
      if (error?.response?.data?.errorCode === 'INSI-COMP-003') {
        return error?.response;
      }
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        // localStorage.clear();
        // deleteAllCognitoCookies();
        cleanSession();
      }
      //@ts-ignore
      if (!error?.response?.data?.errorCode) return Promise.reject();

      const apiError = new ApiError(
        error.response?.status || 0,
        error.response?.data
      );
      alert('error', getErrorMessage(apiError.errorCode));
      return Promise.reject(apiError);
    }
  );
};

export const getErrorMessage = (errorCode: string) => {
  return (
    (API_ERRORS_FR as { [index: string]: string })[errorCode] ||
    'Une erreur est survenue'
  );
};

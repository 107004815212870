import { Amplify } from 'aws-amplify';
import {
  confirmSignUp,
  resendSignUpCode,
  signIn as signInAmplify,
  signOut as signOutAmplify,
  resetPassword as resetPasswordAmplify,
  confirmResetPassword as confirmResetPasswordAmplify,
  getCurrentUser,
  fetchAuthSession,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import {
  HOME_URL,
  REACT_APP_SEED_STAGE_NAME,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_DOMAIN_PREFIX,
  COOKIES_DOMAIN,
} from '../../constantz';
import { isNotLocal } from '../../helpers';
import { jwtDecode } from 'jwt-decode';
import { cleanSession } from '../helpers';
export type LogInInput = {
  email: string;
  password: string;
};
export const LOCAL_OR_STAGE = isNotLocal(REACT_APP_SEED_STAGE_NAME)
  ? REACT_APP_SEED_STAGE_NAME
  : 'develop';
const CLIENT_METADATA = {
  useCommon: 'true',
  useSso: 'false',
  HOME_URL,
  //logoUrl: `${ASSETS_S3_BASE_URL}/inops.png`,
  clientId: REACT_APP_COGNITO_CLIENT_ID as string,
  companyName: 'Connecteed.fr',
};

export const initialize = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: REACT_APP_COGNITO_POOL_ID as string,
        userPoolClientId: REACT_APP_COGNITO_CLIENT_ID as string,
        loginWith: {
          oauth: {
            domain: REACT_APP_COGNITO_DOMAIN_PREFIX as string,
            scopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${HOME_URL}/`],
            redirectSignOut: [`${HOME_URL}/login`],
            responseType: 'code',
          },
        },
      },
    },
  });
  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
      domain: isNotLocal(REACT_APP_SEED_STAGE_NAME)
        ? COOKIES_DOMAIN
        : 'localhost',
      expires: 10,
    })
  );
};
export const currentSession = async (forceRefresh?: boolean) => {
  try {
    const { tokens } = await fetchAuthSession({
      forceRefresh: forceRefresh,
    });
    if (tokens) {
      const token = tokens?.accessToken?.toString();
      const exp = (jwtDecode(token) as any)?.exp;
      localStorage.token = token;
      localStorage.exp = exp;
      return token;
    }
  } catch (err) {
    console.log(err);
    cleanSession();
  }
};
export const refreshSessionToken = async () => {
  let token = await currentSession(true);
  return token;
};
export const signIn = async ({ email, password }: LogInInput) => {
  // sign the user with cognito
  let res = await signInAmplify({ username: email, password });
  return res;
};

export const verifyEmail = async (email: string, code: string) => {
  return confirmSignUp({
    username: email,
    confirmationCode: code,
    options: {
      clientMetadata: { useCommon: 'true' },
    },
  });
};

export const resendCode = async (username: string) => {
  return resendSignUpCode({
    username,
    options: {
      clientMetadata: {
        ...CLIENT_METADATA,
        actionUrl: `${HOME_URL}/verify-email`,
      },
    },
  });
};

export const resetPasswordAWS = async (email: string) => {
  return resetPasswordAmplify({
    username: email,
    options: {
      clientMetadata: {
        ...CLIENT_METADATA,
        actionUrl: `${HOME_URL}/confirm-reset-password`,
      },
    },
  });
};

export const confirmResetPassword = async (
  email: string,
  code: string,
  password: string
) =>
  confirmResetPasswordAmplify({
    username: email,
    confirmationCode: code,
    newPassword: password,
  });
export const getUser = async () => {
  // const user = await Amplify.currentUserInfo();
  const user = await getCurrentUser();
  if (user) {
    return {
      email: user?.signInDetails?.loginId,
      cognitoUserId: user.username,
    };
  } else return null;
};

export const signOut = async () => {
  await signOutAmplify();
  localStorage.clear();
  window.location.href = '/login';
};
